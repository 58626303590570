
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
button,
input {
  font-family: 'Poppins', sans-serif;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.Toastify__toast-theme--dark {
  background-color: #00000076 !important;
}
